import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';

import { CreatorsPerPage, CreatorTableData } from '~api/model/creator';
import { BillingPeriod } from '~api/model/payout-cycle';

import { CreatorLevel } from '~constants/creatorLevel';

import { message } from '~helper/_common';

import { useOnUnmount } from '~hooks/useOnUnmount';
import { useVisitCreatorProfile } from '~hooks/useVisitCreatorProfile';

import { CreatorSource, resetAllCreatorFilters, setCreatorsPage, setCreatorsPerPage, useCreatorsPerPage, useCreatorTablePage } from '~modules/creatorFilter';

import { Paper } from '~components/atoms/Paper';
import { PayoutStatusChangeConfirmDialog } from '~components/organism/PayoutStatusChangeConfirmDialog';
import { ComponentCommonProps } from '~components/types';

import * as Cells from './CreatorTableCells';
import { HeaderCell } from './HeaderCell';
import { LoadingCell } from './LoadingCell';
import { usePaginatedFilteredAndSortedCreators } from './usePaginatedFilteredAndSortedCreators';

import style from './CreatorTable.module.scss';

export type CreatorTableColumns = Exclude<keyof CreatorTableData, 'fraud' | 'payoutCycleRunId' | 'imageUrl' | 'isCancellationDue'| 'isCreditNoteDocumentDraftAvailable'>
| 'payoutStatusChange'
| 'nicknameWithLevelBadge'
| 'totalViews'
| 'brandVideoAssignment'
| 'payoutMethod'
| 'creditNoteDocumentDraft';

export type CreatorTableProps = ComponentCommonProps & {
    creators: Array<CreatorTableData>;
    loading?: boolean;
    visibleFields: Array<CreatorTableColumns>;
    creatorSource: CreatorSource;
    creatorSourceId?: string | null;
    paidOut?: boolean;
    linkToCreator?: boolean;
    showFilterAndSort?: boolean;
    showPagination?: boolean;
    onRowClick?: (creator: CreatorTableData) => void;
    billingPeriod?: BillingPeriod;
};

const cellComponents: Record<CreatorTableColumns, React.FC<Cells.CreatorTableCellProps>> = {
    levelId: Cells.CreatorLevelCell,
    creatorId: Cells.Standard,
    nickname: Cells.Nickname,
    nicknameWithLevelBadge: Cells.NicknameWithLevelBadge,
    email: Cells.Standard,
    blocked: Cells.Blocked,
    liveViewCount: Cells.Numeric,
    replayViewCount: Cells.Numeric,
    showCount: Cells.Numeric,
    clipCount: Cells.Numeric,
    beautyPointsCollected: Cells.Numeric,
    beautyPointsCollectedSinceLastPayout: Cells.Numeric,
    beautyPointsPaidOut: Cells.Numeric,
    cashCollected: Cells.Currency,
    cashCollectedSinceLastPayout: Cells.Currency,
    cashPaidOut: Cells.Currency,
    nextPayoutDate: Cells.PayoutDate,
    payoutStatusChange: Cells.PayoutStatusChange,
    creationDate: Cells.CreationTime,
    billableLiveViewCount: Cells.Numeric,
    billableReplayViewCount: Cells.Numeric,
    compensableLiveViewCount: Cells.Numeric,
    compensableReplayViewCount: Cells.Numeric,
    payoutStatus: Cells.PayoutStatus,
    payoutMethod: Cells.PayoutMethod,
    totalViews: Cells.TotalViews,
    city: Cells.Standard,
    age: Cells.Numeric,
    gender: Cells.Gender,
    brandVideoAssignment: Cells.BrandVideoAssignment,
    billingPeriodStart: Cells.BillingPeriod,
    billingPeriodEnd: Cells.BillingPeriod,
    creditNoteDocumentDraft: Cells.CreditNoteDocumentDraft,
    cdcId: Cells.Standard,
};

export const CreatorTable: React.FC<CreatorTableProps> = ({
    billingPeriod,
    className,
    creators,
    loading,
    visibleFields,
    creatorSource,
    creatorSourceId,
    linkToCreator,
    paidOut,
    showFilterAndSort = true,
    showPagination = true,
    onRowClick,
}) => {
    const dispatcher = useDispatch();
    const creatorsPerPage = useCreatorsPerPage();
    const page = useCreatorTablePage();
    const visitCreatorProfile = useVisitCreatorProfile();

    const {
        totalCount,
        creatorsToShow,
    } = usePaginatedFilteredAndSortedCreators(creators, visibleFields);

    useOnUnmount(() => {
        dispatcher(resetAllCreatorFilters('no-operation', null));
    });

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        dispatcher(setCreatorsPage(page, creatorSource, creatorSourceId));
    };

    const handleCreatorsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const rowsPerPage = parseInt(event.target.value, 10);

        dispatcher(setCreatorsPerPage(rowsPerPage as CreatorsPerPage, creatorSource, creatorSourceId));
    };

    const handleCreatorRowClick = (creator: CreatorTableData) => (event: React.MouseEvent<HTMLElement>) => {
        if (onRowClick) {
            onRowClick(creator);
        }
        if (linkToCreator) {
            visitCreatorProfile(creator.creatorId, event);
        }
    };

    const availableCreatorLevels = useMemo(() => {
        const levels = new Set<CreatorLevel>();

        for (const creator of creators) {
            if (creator.levelId) {
                levels.add(creator.levelId);
            }
        }

        return [ ...levels ];
    }, [ creators ]);

    return (
        <Paper className={ className } data-test="creatorTable">
            <Table>
                <TableHead>
                    <TableRow hover={ false } data-test="creatorTable.head">
                        { visibleFields.map((fieldName) => (
                            <HeaderCell
                                className={ fieldName === 'nicknameWithLevelBadge' ? style.nameHeaderWithBadge : '' }
                                key={ fieldName }
                                creatorSource={ creatorSource }
                                creatorSourceId={ creatorSourceId }
                                fieldName={ fieldName }
                                showFilterAndSort={ showFilterAndSort }
                                availableCreatorLevels={ availableCreatorLevels }
                                data-test={ `creatorTable.head.${fieldName}` } />
                        )) }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { loading && [ ...Array(creatorsPerPage).keys() ].map(key => (
                        <TableRow key={ key } data-test="creatorTable.loading">
                            { visibleFields.map((fieldName) => (
                                <LoadingCell
                                    key={ fieldName }
                                    fieldName={ fieldName }
                                    showSkeleton={ ![ 'blocked', 'payoutStatusChange' ].includes(fieldName) }
                                    data-test={ `creatorTable.loading.${fieldName}` } />
                            )) }
                        </TableRow>
                    )) }
                    { !loading && creatorsToShow.map(creator => (
                        <TableRow key={ JSON.stringify(creator) }
                            onClick={ handleCreatorRowClick(creator) }
                            data-test="creatorTable.entry" >
                            { visibleFields.map((fieldName) => {
                                const CellComponent = cellComponents[fieldName];

                                return (
                                    <CellComponent
                                        key={ fieldName }
                                        creator={ creator }
                                        fieldName={ fieldName as keyof CreatorTableData }
                                        paidOut={ paidOut }
                                        billingPeriod={ billingPeriod }
                                        data-test={ `creatorTable.entry.${fieldName}` } />
                                );
                            }) }
                        </TableRow>
                    )) }
                    { !loading && totalCount === 0 && (
                        <TableRow data-test="creatorTable.noResults" hover={ false }>
                            <TableCell
                                align="center"
                                colSpan={ visibleFields.length }
                                data-test="creatorTable.noResults.label">
                                { message('creator.table.empty') }
                            </TableCell>
                        </TableRow>
                    ) }
                </TableBody>
            </Table>
            <Divider />
            { showPagination && (
                <TablePagination
                    component="div"
                    count={ loading ? creatorsPerPage : totalCount || 0 }
                    page={ page }
                    onPageChange={ handlePageChange }
                    rowsPerPage={ creatorsPerPage }
                    onRowsPerPageChange={ handleCreatorsPerPageChange }
                    data-test="creatorTable.pagination" />
            ) }
            <PayoutStatusChangeConfirmDialog />
        </Paper>
    );
};
