import React from 'react';
import { useDispatch } from 'react-redux';
import { FileOpen } from '@mui/icons-material';
import { Checkbox, IconButton, TableCell, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { CreatorTableData } from '~api/model/creator';
import { BillingPeriod as BillingPeriodType, PayoutCycleRunCreator, PayoutMethod as PayoutMethodType } from '~api/model/payout-cycle';

import { formatCurrency, formatDate, formatNumber, message } from '~helper/_common';

import { useBrandVideoCreatorSelectionDialog } from '~modules/brandVideoCreatorSelectionDialog';
import { PayoutStatusChange as PayoutStatusChangeType, setPayoutStatusChange } from '~modules/payoutStatusChangeConfirmDialog';

import { Button } from '~components/atoms/Button';
import { BeautypointsChip, CanceledChip, FraudChip, MoneyChip, OnHoldChip, StoppedChip } from '~components/atoms/Chip';
import { GenderIcon } from '~components/atoms/GenderIcon';
import { LevelBadge } from '~components/atoms/LevelBadge';
import { RequireRole } from '~components/utils/RequireRole';

import { showCreditNoteDocumentDraft } from '~sagas/showCreditNoteDocumentDraft';

import style from './CreatorTable.module.scss';

export type CreatorTableCellProps = {
    creator: CreatorTableData;
    fieldName: keyof CreatorTableData;
    paidOut?: boolean;
    billingPeriod?: BillingPeriodType;
};

export const Standard: React.FC<CreatorTableCellProps> = ({
    creator,
    fieldName,
}) => {
    return (
        <TableCell data-test={ `creatorTable.entry.${fieldName}` }>
            { creator[fieldName] }
        </TableCell>
    );
};

export const Nickname: React.FC<CreatorTableCellProps> = ({
    creator,
}) => {
    return (
        <TableCell data-test="creatorTable.entry.nickname">
            { creator.nickname }
            { creator.fraud && <div><FraudChip /></div> }
        </TableCell>
    );
};

export const NicknameWithLevelBadge: React.FC<CreatorTableCellProps> = ({
    creator,
}) => {
    return (
        <TableCell data-test="creatorTable.entry.nicknameWithLevelBadge">
            { creator?.levelId && <LevelBadge level={ creator.levelId } className={ style.badgeForName } /> }
            { creator.nickname }
            { creator.fraud && <div className={ style.fraudFlagForNameWithLevelBadge }><FraudChip /></div> }
        </TableCell>
    );
};

export const Numeric: React.FC<CreatorTableCellProps> = ({
    creator,
    fieldName,
}) => {
    return (
        <TableCell align="right" data-test={ `creatorTable.entry.${fieldName}` }>
            { typeof creator[fieldName] === 'number' ? formatNumber(creator[fieldName] as number) : '' }
        </TableCell>
    );
};

export const Currency: React.FC<CreatorTableCellProps> = ({
    creator,
    fieldName,
}) => {
    return (
        <TableCell align="right" data-test={ `creatorTable.entry.${fieldName}` }>
            { typeof creator[fieldName] === 'number' ? formatCurrency(creator[fieldName] as number) : '' }
        </TableCell>
    );
};

export const CreatorLevelCell: React.FC<CreatorTableCellProps> = ({
    creator,
}) => {
    const level = creator.levelId;

    return (
        <TableCell data-test="creatorTable.entry.level">
            { level && (
                <div className={ style.level }>
                    <LevelBadge level={ level } className={ style.levelBadge } data-test="creatorTable.entry.level.badge" />
                    { message(`creator.level.${level}`) }
                </div>
            ) }
        </TableCell>
    );
};

export const Gender: React.FC<CreatorTableCellProps> = ({
    creator,
}) => {
    return (
        <TableCell data-test={ 'creatorTable.entry.gender' }>
            { creator.gender && (
                <GenderIcon
                    gender={ creator.gender }
                    fontSize="inherit"
                    data-test={ 'creatorTable.entry.gender.icon' } />
            ) }
        </TableCell>
    );
};

export const PayoutDate: React.FC<CreatorTableCellProps> = ({
    creator,
    fieldName,
    paidOut,
}) => {
    let nextPayoutDate = '';

    if (creator[fieldName]) {
        nextPayoutDate = formatDate(creator[fieldName] as string);

        if (creator.beautyPointsCollectedSinceLastPayout === 0 || creator.fraud || creator.blocked) {
            nextPayoutDate += ` ${message('creator.table.label.nextPayoutDate.earliest')}`;
        }
    }

    return (
        <TableCell data-test={ `creatorTable.entry.${fieldName}` }>
            { creator.blocked && (paidOut ? <CanceledChip /> : <OnHoldChip />) }
            { nextPayoutDate && (
                <div data-test={ `creatorTable.entry.${fieldName}.date` }>
                    { nextPayoutDate }
                </div>
            ) }
        </TableCell>
    );
};

export const Blocked: React.FC<CreatorTableCellProps> = ({
    creator,
    paidOut,
}) => {
    return (
        <TableCell data-test="creatorTable.entry.blocked">
            { creator.blocked && (paidOut ? <CanceledChip /> : <OnHoldChip />) }
        </TableCell>
    );
};

export const PayoutStatusChange: React.FC<CreatorTableCellProps> = ({
    creator
}) => {
    const dispatch = useDispatch();
    const handlePayoutStatusChangeClick = (creator: CreatorTableData, status: PayoutStatusChangeType) => (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        dispatch(setPayoutStatusChange(creator as PayoutCycleRunCreator, status));
    };

    return (
        <TableCell data-test="creatorTable.entry.payoutStatusChange" align="right">
            <RequireRole roles={ [ 'ADMIN', 'COMPENSATION_MANAGER', 'SUPERUSER' ] }>
                { creator.blocked
                    ? <Button
                        variant="text"
                        color="primary"
                        onClick={ handlePayoutStatusChangeClick(creator, 'resume') }
                        data-test="creatorTable.entry.payoutStatusChange.resumePayout">
                        { message('creator.table.actions.resumePayout') }
                    </Button>
                    : <>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={ handlePayoutStatusChangeClick(creator, 'onHold') }
                            data-test="creatorTable.entry.payoutStatusChange.setPayoutOnHold">
                            { message('creator.table.actions.setPayoutOnHold') }
                        </Button>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={ handlePayoutStatusChangeClick(creator, 'stop') }
                            data-test="creatorTable.entry.payoutStatusChange.stopPayout">
                            { message('creator.table.actions.stopPayout') }
                        </Button>
                    </>
                }
            </RequireRole>
        </TableCell>
    );
};

export const CreationTime: React.FC<CreatorTableCellProps> = ({ creator, fieldName, }) => {
    return (
        <TableCell data-test={ `creatorTable.entry.${fieldName}` }>
            { formatDate(creator[fieldName] as string) }
        </TableCell>
    );
};

export const BillingPeriod: React.FC<CreatorTableCellProps> = ({ billingPeriod, creator, fieldName, }) => {
    const isHighlighted = Boolean(billingPeriod) && ((fieldName === 'billingPeriodStart' && creator[fieldName] !== billingPeriod?.start) || (fieldName === 'billingPeriodEnd' && creator[fieldName] !== billingPeriod?.end));

    return (
        <TableCell className={ clsx(isHighlighted && style.highlightedText) } data-test={ `creatorTable.entry.${fieldName}` }>
            { formatDate(creator[fieldName] as string) }
        </TableCell>
    );
};

export const PayoutStatus: React.FC<CreatorTableCellProps> = ({
    creator
}) => {
    return (
        <TableCell data-test="creatorTable.entry.payoutStatus">
            { (creator.payoutStatus === 'ON_HOLD' || creator.payoutStatus === 'ON_HOLD_BY_PAYOUT_RUN') && (<OnHoldChip />) }
            { creator.payoutStatus === 'STOPPED' && <StoppedChip /> }
            { creator.payoutStatus === 'CANCELLED' && (<CanceledChip />) }
        </TableCell>
    );
};

export const PayoutMethod: React.FC<CreatorTableCellProps> = ({
    creator
}) => {
    return (
        <TableCell data-test="creatorTable.entry.payoutMethod">
            { (creator.payoutMethod === PayoutMethodType.CASH) && <MoneyChip /> }
            { (creator.payoutMethod === PayoutMethodType.BEAUTY_POINTS) && <BeautypointsChip /> }
        </TableCell>
    );
};

export const TotalViews: React.FC<CreatorTableCellProps> = ({
    creator,
}) => {
    const totalViews = (creator?.billableLiveViewCount ?? 0) + (creator?.billableReplayViewCount ?? 0);

    return (
        <TableCell data-test="creatorTable.entry.totalViews">
            { formatNumber(totalViews) }
        </TableCell>
    );
};

export const BrandVideoAssignment: React.FC<CreatorTableCellProps> = ({
    creator
}) => {
    const { brandVideo } = useBrandVideoCreatorSelectionDialog();

    return (
        <TableCell className={ style.brandVideoAssignment } data-test="creatorTable.entry.brandVideoAssignment">
            <Checkbox
                checked={ Boolean(brandVideo?.assignedCreators.find(assignedCreator => assignedCreator.creatorId === creator.creatorId)) }
                data-test="creatorTable.entry.brandVideoAssignment.checkbox"
            />
        </TableCell>
    );
};

export const CreditNoteDocumentDraft: React.FC<CreatorTableCellProps> = ({
    creator
}) => {
    const dispatch = useDispatch();

    const handleDownloadCreditNoteDocumentDraftClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        if (creator.creatorId && creator.payoutCycleRunId) {
            dispatch(showCreditNoteDocumentDraft({ creatorId: creator.creatorId, payoutCycleRunId: creator.payoutCycleRunId, source: 'payoutCycleRun' }));
        }
    };

    return (
        <TableCell data-test="creatorTable.entry.creditNoteDocumentDraft">
            <RequireRole roles={ [ 'ADMIN', 'COMPENSATION_MANAGER', 'SUPERUSER' ] }>
                { creator.isCreditNoteDocumentDraftAvailable && (
                    <Tooltip title={ message('creator.table.actions.showCreditNoteDocumentDraft') }>
                        <IconButton
                            onClick={ handleDownloadCreditNoteDocumentDraftClick }
                            data-test="creatorTable.entry.creditNoteDocumentDraft.button"
                        >
                            <FileOpen />
                        </IconButton>
                    </Tooltip>
                ) }
            </RequireRole>
        </TableCell>
    );
};
