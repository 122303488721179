import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { Routes } from '~constants/routes';

export const useVisitCreatorProfile = (): ((creatorId?: string, event?: React.MouseEvent<HTMLElement>) => void) => {
    const router = useRouter();

    return useCallback((creatorId?: string, event?: React.MouseEvent<HTMLElement>) => {
        if (creatorId) {
            const creatorDetailsPageUrl = `${Routes.CREATOR_PROFILE}${creatorId}`;

            if (event?.ctrlKey || event?.metaKey) {
                window.open(creatorDetailsPageUrl, '_blank');
            } else {
                router.push(creatorDetailsPageUrl, creatorDetailsPageUrl);
            }
        }
    }, []);
};
