import { useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Checkbox, Divider, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import { partial } from 'lodash';

import { CreatorLevel as CreatorLevelEnum, creatorLevelOrder } from '~constants/creatorLevel';

import { useFormInputValue } from '~hooks/useFormInputValue';

import { CreatorLevel } from '~components/molecules/CreatorLevel';
import { FilterButton } from '~components/molecules/FilterButton';
import { ComponentCommonProps } from '~components/types';

import style from './CreatorLevelFilter.module.scss';

export type CreatorLevelFilterProps = ComponentCommonProps & {
    value?: string | null;
    availableCreatorLevels: CreatorLevelEnum[];
    onChange: (value: string | null) => void;
}

export const CreatorLevelFilter: React.FC<CreatorLevelFilterProps> = ({
    value,
    onChange,
    availableCreatorLevels,
    'data-test': dataTest = 'creatorLevelFilter'
}) => {
    const { value: filterValue, setValue: setFilterValue } = useFormInputValue(value || availableCreatorLevels.join(','));
    const handleApplyButtonClick = (requestClose: () => void) => {
        onChange(filterValue || null);
        requestClose();
    };
    const handleCancelButtonClick = (requestClose: () => void) => {
        onChange(null);
        setFilterValue(availableCreatorLevels.join(','));
        requestClose();
    };
    const checkedCreatorLevels = useMemo(() => {
        return new Set<CreatorLevelEnum>(((filterValue)?.split(',') as CreatorLevelEnum[] ?? []).filter(Boolean));
    }, [ filterValue ]);
    const handleCreatorLevelClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const creatorLevel = event.target.name as CreatorLevelEnum;

        if (event.target.checked) {
            checkedCreatorLevels.add(creatorLevel);
        } else {
            checkedCreatorLevels.delete(creatorLevel);
        }

        const newFilterValue = [ ...checkedCreatorLevels ]
            .sort((a, b) => creatorLevelOrder.indexOf(a) - creatorLevelOrder.indexOf(b))
            .join(',');

        setFilterValue(newFilterValue);
        onChange(newFilterValue);
    };

    return (
        <FilterButton
            value={ value }
            data-test={ dataTest }
            render={ ({ requestClose }) => (
                <div className={ style.creatorLevels } data-test={ dataTest }>
                    <FormGroup>
                        { creatorLevelOrder.map(level => (
                            <FormControlLabel
                                key={ level }
                                control={
                                    <Checkbox
                                        name={ level }
                                        checked={ checkedCreatorLevels.has(level) && availableCreatorLevels.includes(level) }
                                        disabled={ !availableCreatorLevels.includes(level) }
                                        onChange={ handleCreatorLevelClick }
                                        data-test={ `${dataTest}.checkbox.${level}` }
                                    />
                                }
                                label={ <CreatorLevel level={ level } /> }
                            />
                        )) }
                    </FormGroup>
                    <div className={ style.actions }>
                        <IconButton
                            size="small"
                            onClick={ partial(handleCancelButtonClick, requestClose) }
                            data-test={ `${dataTest}.cancel` }
                        >
                            <ClearIcon color="secondary" />
                        </IconButton>
                        <Divider sx={ { height: 28, m: 0.5 } } orientation="vertical" />
                        <IconButton
                            size="small"
                            onClick={ partial(handleApplyButtonClick, requestClose) }
                            data-test={ `${dataTest}.apply` }
                        >
                            <CheckIcon color="primary" />
                        </IconButton>
                    </div>
                </div>
            ) }
        />
    );
};
