import { useDispatch } from 'react-redux';

import { FilterableCreatorTableFields } from '~api/model/creator';

import { CreatorLevel } from '~constants/creatorLevel';

import { CreatorSource, setFilterForCreator, useCreatorFilter } from '~modules/creatorFilter';

import { CreatorLevelFilter } from '~components/molecules/CreatorLevelFilter';
import { GenderFilter } from '~components/molecules/GenderFilter';
import { NumericRangeFilter } from '~components/molecules/NumericRangeFilter';
import { PayoutMethodFilter } from '~components/molecules/PayoutMethodFilter';
import { TextFilter } from '~components/molecules/TextFilter';
import { ComponentCommonProps } from '~components/types';

export type CreatorFilterButtonProps = ComponentCommonProps & {
    name: keyof FilterableCreatorTableFields;
    creatorSource: CreatorSource;
    creatorSourceId?: string | null;
    availableCreatorLevels: CreatorLevel[];
};

export const CreatorFilterButton: React.FC<CreatorFilterButtonProps> = ({
    name,
    creatorSource,
    creatorSourceId,
    availableCreatorLevels,
    'data-test': dataTest = 'creatorFilterButton'
}) => {
    const dispatcher = useDispatch();
    const creatorFilter = useCreatorFilter();

    const handleChange = (value: string | null) => {
        dispatcher(
            setFilterForCreator(
                { [name]: value },
                creatorSource,
                creatorSourceId
            )
        );
    };

    switch (name) {
        case 'levelId':
            return (
                <CreatorLevelFilter
                    value={ creatorFilter?.[name] }
                    onChange={ handleChange }
                    availableCreatorLevels={ availableCreatorLevels }
                    data-test={ dataTest } />
            );
        case 'gender':
            return (
                <GenderFilter
                    value={ creatorFilter?.[name] }
                    onChange={ handleChange }
                    data-test={ dataTest } />
            );
        case 'age':
            return (
                <NumericRangeFilter
                    value={ creatorFilter?.[name] }
                    onChange={ handleChange }
                    data-test={ dataTest } />
            );
        case 'payoutMethod':
            return (
                <PayoutMethodFilter
                    value={ creatorFilter?.[name] }
                    onChange={ handleChange }
                    data-test={ dataTest } />
            );
        default:
            return (
                <TextFilter
                    value={ creatorFilter?.[name] }
                    onChange={ handleChange }
                    data-test={ dataTest } />
            );
    }
};
