import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Divider, IconButton, InputBase } from '@mui/material';
import { partial } from 'lodash';

import { message } from '~helper/_common';

import { useFormInputValue } from '~hooks/useFormInputValue';

import { FilterButton } from '~components/molecules/FilterButton';
import style from '~components/molecules/FilterButton/FilterButton.module.scss';
import { ComponentCommonProps } from '~components/types';

export type NumericRangeFilterProps = ComponentCommonProps & {
    value?: string | null;
    onChange: (value: string | null) => void;
};

export const NumericRangeFilter: React.FC<NumericRangeFilterProps> = ({
    value,
    onChange,
    'data-test': dataTest = 'textFilter',
}) => {
    const [ fromValue, toValue ] = value?.split('|') || [];
    const { value: fromFilterValue, setValue: setFromFilterValue } = useFormInputValue(fromValue || '');
    const { value: toFilterValue, setValue: setToFilterValue } = useFormInputValue(toValue || '');

    const handleSubmitButtonClick = (requestClose: () => void) => {
        onChange(`${fromFilterValue || ''}|${toFilterValue || ''}` || null);
        requestClose();
    };

    const handleCancelButtonClick = (requestClose: () => void) => {
        onChange(null);
        setFromFilterValue('');
        setToFilterValue('');
        requestClose();
    };

    const handleFromInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFromFilterValue(event.target.value);
    };

    const handleToInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToFilterValue(event.target.value);
    };

    const handleKeyDown = (requestClose: () => void, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onChange(`${fromFilterValue || ''}|${toFilterValue || ''}` || null);
            requestClose();
        }
        if (event.key === 'Esc' || event.key === 'Escape') {
            event.preventDefault();
            onChange(null);
            setFromFilterValue('');
            setToFilterValue('');
            requestClose();
        }
    };

    const handleClose = () => {
        setFromFilterValue(fromValue || '');
        setToFilterValue(toValue || '');
    };

    return (
        <FilterButton
            value={ value }
            onClose={ handleClose }
            data-test={ dataTest }
            render={ ({ requestClose }) => (
                <>
                    <InputBase
                        placeholder={ message('creator.filterBy.from') }
                        size="small"
                        type="number"
                        className={ style.textField }
                        value={ fromFilterValue }
                        onChange={ handleFromInputChange }
                        onKeyDown={ partial(handleKeyDown, requestClose) }
                        autoFocus
                        tabIndex={ 1 }
                        inputProps={ {
                            'data-test': `${dataTest}.filterValue.from`
                        } }
                    />
                    <Divider sx={ { height: 28, m: 0.5 } } orientation="vertical" />
                    <InputBase
                        placeholder={ message('creator.filterBy.to') }
                        size="small"
                        type="number"
                        className={ style.textField }
                        value={ toFilterValue }
                        onChange={ handleToInputChange }
                        onKeyDown={ partial(handleKeyDown, requestClose) }
                        tabIndex={ 2 }
                        inputProps={ {
                            'data-test': `${dataTest}.filterValue.to`
                        } }
                    />
                    <IconButton
                        size="small"
                        onClick={ partial(handleCancelButtonClick, requestClose) }
                        data-test={ `${dataTest}.cancelFilterButton` }
                    >
                        <ClearIcon color="secondary" />
                    </IconButton>
                    <Divider sx={ { height: 28, m: 0.5 } } orientation="vertical" />
                    <IconButton
                        size="small"
                        onClick={ partial(handleSubmitButtonClick, requestClose) }
                        data-test={ `${dataTest}.applyFilterButton` }
                    >
                        <CheckIcon color="primary" />
                    </IconButton>
                </>
            ) }
        />
    );
};
