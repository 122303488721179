import { CreatorTableData, FilterableCreatorTableFields } from '~api/model/creator';

import { useCreatorFilter, useCreatorSorting, useCreatorsPerPage, useCreatorTablePage } from '~modules/creatorFilter';

import { CreatorTableColumns } from '~components/organism/CreatorTable/CreatorTable';

export type UsePaginatedFilteredAndSortedCreatorsReturnValue = {
    creatorsToShow: Array<CreatorTableData>;
    totalCount: number;
};

export const usePaginatedFilteredAndSortedCreators = (
    rawCreators: Array<CreatorTableData>,
    visibleFields?: CreatorTableColumns[],
): UsePaginatedFilteredAndSortedCreatorsReturnValue => {
    const creatorsPerPage = useCreatorsPerPage();
    const page = useCreatorTablePage();
    const sorting = useCreatorSorting();
    const filter = useCreatorFilter();

    const creatorToShow = rawCreators
        .filter(creator => {
            if (!filter) {
                return true;
            }

            return !Object.entries(filter).some(([ filterKey, filterValue ]) => {
                const filterKeyToUse: keyof FilterableCreatorTableFields = filterKey as keyof FilterableCreatorTableFields;

                if (visibleFields && !visibleFields?.includes(filterKeyToUse)) {
                    return false;
                }
                if (filterValue === null) {
                    return false;
                }
                if (!creator[filterKeyToUse]) {
                    return true;
                }

                const element = creator[filterKeyToUse] as string;

                if (filterKeyToUse === 'levelId') {
                    if (filterValue === null) {
                        return false;
                    }

                    const levels = filterValue.split(',').filter(Boolean);

                    for (const level of levels) {
                        if (element?.toUpperCase().indexOf(level) === 0) {
                            return false;
                        }
                    }

                    return true;
                }

                if (filterKeyToUse === 'gender') {
                    if (filterValue === null) {
                        return false;
                    }

                    const genders = filterValue.split(',').filter(Boolean);

                    for (const gender of genders) {
                        if (element?.toUpperCase().indexOf(gender) === 0) {
                            return false;
                        }
                    }

                    return true;
                }

                if (filterKeyToUse === 'payoutMethod') {
                    if (filterValue === null) {
                        return false;
                    }

                    const paymentMethods = filterValue.split(',').filter(Boolean);

                    for (const paymentMethod of paymentMethods) {
                        if (element?.toUpperCase().indexOf(paymentMethod) === 0) {
                            return false;
                        }
                    }

                    return true;
                }

                if (filterKeyToUse === 'age') {
                    const [ from, to ] = filterValue
                        .split('|')
                        .map(value => parseInt(value));
                    const elementAsInt = parseInt(element);

                    return (from && elementAsInt < from) || (to && elementAsInt > to);
                }

                return element?.toLowerCase().indexOf(filterValue?.toLowerCase() || '') < 0 || false;
            });
        })
        .sort((creatorA, creatorB) => {
            if (!sorting) {
                return 0;
            }

            if (creatorA[sorting.by] === null && creatorB[sorting.by] === null) {
                return 0;
            }
            if (creatorA[sorting.by] === null) {
                return sorting.direction === 'DESC' ? -1 : 1;
            }
            if (creatorB[sorting.by] === null) {
                return sorting.direction === 'DESC' ? 1 : -1;
            }

            // @ts-ignore
            if (creatorA[sorting.by] < creatorB[sorting.by]) {
                return sorting.direction === 'DESC' ? 1 : -1;
            }

            // @ts-ignore
            if (creatorA[sorting.by] > creatorB[sorting.by]) {
                return sorting.direction === 'DESC' ? -1 : 1;
            }

            return 0;
        });

    return {
        totalCount: creatorToShow.length,
        creatorsToShow: creatorToShow.slice(page * creatorsPerPage, ((page + 1) * creatorsPerPage)),
    };
};
