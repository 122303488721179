import { Transgender } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import clsx from 'clsx';

import { CreatorLevel } from '~constants/creatorLevel';

import { isMessageKey, message } from '~helper/_common';
import { isFilterableCreatorTableFieldName, isSortableCreatorTableFieldName } from '~helper/_creator';

import { CreatorSource } from '~modules/creatorFilter';

import { CreatorFilterButton } from './CreatorFilterButton';
import { CreatorSortButton } from './CreatorSortButton';
import { CreatorTableColumns } from './CreatorTable';

import style from './HeaderCell.module.scss';

export type HeaderCellProps = {
    creatorSource: CreatorSource;
    creatorSourceId?: string | null;
    fieldName: CreatorTableColumns;
    showFilterAndSort: boolean;
    availableCreatorLevels: CreatorLevel[];
    className?: string;
};

const isRightAligned = new Set<CreatorTableColumns>([
    'age',
    'beautyPointsCollected',
    'beautyPointsCollectedSinceLastPayout',
    'beautyPointsPaidOut',
    'billableLiveViewCount',
    'billableReplayViewCount',
    'cashPaidOut',
    'cashCollected',
    'cashPaidOut',
    'clipCount',
    'compensableLiveViewCount',
    'compensableReplayViewCount',
    'liveViewCount',
    'replayViewCount',
    'showCount'
]);

export const HeaderCell: React.FC<HeaderCellProps> = ({
    creatorSource,
    creatorSourceId,
    fieldName,
    showFilterAndSort,
    availableCreatorLevels,
    className,
}) => {
    const messageKey = `creator.table.label.${fieldName}`;
    const dataTest = `creatorTable.head.${fieldName}`;
    const isFilterableCell = showFilterAndSort && isFilterableCreatorTableFieldName(fieldName);
    const isSortableCell = showFilterAndSort && isSortableCreatorTableFieldName(fieldName);

    return (
        <TableCell
            className={ clsx(className, {
                [style.withIcon]: isFilterableCell || isSortableCell
            }) }
            align={ isRightAligned.has(fieldName) ? 'right' : 'left' }
            data-test={ dataTest }
        >
            { isMessageKey(messageKey) && message(messageKey) }
            { fieldName === 'gender' && <Transgender className={ style.gender } data-test={ `${dataTest}.genderIcon` } /> }
            { isFilterableCell && (
                <CreatorFilterButton
                    name={ fieldName }
                    creatorSource={ creatorSource }
                    creatorSourceId={ creatorSourceId }
                    availableCreatorLevels={ availableCreatorLevels }
                    data-test={ `${dataTest}.filterButton` } />
            ) }
            { isSortableCell && (
                <CreatorSortButton
                    name={ fieldName }
                    creatorSource={ creatorSource }
                    creatorSourceId={ creatorSourceId }
                    data-test={ `${dataTest}.sortButton` } />
            ) }
        </TableCell>
    );
};
