import { Skeleton, TableCell } from '@mui/material';

import { CreatorTableColumns } from '~components/organism/CreatorTable/CreatorTable';

export type LoadingCellProps = {
    fieldName: CreatorTableColumns;
    showSkeleton: boolean;
};

export const LoadingCell: React.FC<LoadingCellProps> = ({
    fieldName,
    showSkeleton,
}) => {
    return (
        <TableCell data-test={ `creatorTable.loading.${fieldName}` }>
            { showSkeleton && <Skeleton variant="text" /> }
        </TableCell>
    );
};
