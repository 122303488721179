import { useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Checkbox, Divider, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import { partial } from 'lodash';

import { Gender } from '~api/model/creator';

import { message } from '~helper/_common';

import { useFormInputValue } from '~hooks/useFormInputValue';

import { FilterButton } from '~components/molecules/FilterButton';
import { ComponentCommonProps } from '~components/types';

import style from './GenderFilter.module.scss';

export type GenderFilterProps = ComponentCommonProps & {
    value?: string | null;
    onChange: (value: string | null) => void;
}

export const GenderFilter: React.FC<GenderFilterProps> = ({
    value,
    onChange,
    'data-test': dataTest = 'genderFilter'
}) => {
    const defaultValue = Object.values(Gender).join(',');
    const { value: filterValue, setValue: setFilterValue } = useFormInputValue(value || defaultValue);
    const handleApplyButtonClick = (requestClose: () => void) => {
        onChange(filterValue || null);
        requestClose();
    };
    const handleCancelButtonClick = (requestClose: () => void) => {
        onChange(null);
        setFilterValue(defaultValue);
        requestClose();
    };

    const checkedGenders = useMemo(() => {
        return new Set<Gender>(((filterValue)?.split(',') as Gender[] ?? []).filter(Boolean));
    }, [ filterValue ]);

    const handleGenderClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const gender = event.target.name as Gender;

        if (event.target.checked) {
            checkedGenders.add(gender);
        } else {
            checkedGenders.delete(gender);
        }

        const newFilterValue = [ ...checkedGenders ].join(',');

        setFilterValue(newFilterValue);
        onChange(newFilterValue);
    };

    return (
        <FilterButton
            value={ value }
            data-test={ dataTest }
            render={ ({ requestClose }) => (
                <div className={ style.genders } data-test={ dataTest }>
                    <FormGroup>
                        { Object.values(Gender).map(gender => (
                            <FormControlLabel
                                key={ gender }
                                control={
                                    <Checkbox
                                        name={ gender }
                                        checked={ checkedGenders.has(gender) }
                                        onChange={ handleGenderClick }
                                        data-test={ `${dataTest}.checkbox.${gender}` }
                                    />
                                }
                                label={ message(`creator.table.filter.gender.label.${gender}`) }
                            />
                        )) }
                    </FormGroup>
                    <div className={ style.actions }>
                        <IconButton
                            size="small"
                            onClick={ partial(handleCancelButtonClick, requestClose) }
                            data-test={ `${dataTest}.cancel` }
                        >
                            <ClearIcon color="secondary" />
                        </IconButton>
                        <Divider sx={ { height: 28, m: 0.5 } } orientation="vertical" />
                        <IconButton
                            size="small"
                            onClick={ partial(handleApplyButtonClick, requestClose) }
                            data-test={ `${dataTest}.apply` }
                        >
                            <CheckIcon color="primary" />
                        </IconButton>
                    </div>
                </div>
            ) }
        />
    );
};
