import { useDispatch } from 'react-redux';

import { SortableCreatorTableFields } from '~api/model/creator';

import { CreatorSource, resetCreatorSorting, setCreatorSorting, useCreatorSorting } from '~modules/creatorFilter';

import { SortButton, SortButtonDirection } from '~components/molecules/SortButton';
import { ComponentCommonProps } from '~components/types';

export type CreatorSortButtonProps = ComponentCommonProps & {
    name: keyof SortableCreatorTableFields;
    creatorSource: CreatorSource;
    creatorSourceId?: string | null;
}

export const CreatorSortButton: React.FC<CreatorSortButtonProps> = ({
    name,
    creatorSource,
    creatorSourceId,
    'data-test': dataTest = 'creatorSortButton',
}) => {
    const dispatcher = useDispatch();
    const creatorSorting = useCreatorSorting();
    const direction = creatorSorting?.by === name ? creatorSorting.direction : null;

    const handleButtonClick = (direction: SortButtonDirection) => {
        if (direction === null) {
            dispatcher(resetCreatorSorting(creatorSource, creatorSourceId));
        } else {
            dispatcher(setCreatorSorting({ by: name, direction }, creatorSource, creatorSourceId));
        }
    };

    return (
        <SortButton
            direction={ direction }
            onClick={ handleButtonClick }
            data-test={ dataTest }
        />
    );
};
